import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ProductPage.css'
import { Navigation,  A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import TotalSection4 from '../../TotalWater/TotalSection/TotalSection4';


const ProductPage = () => {
    const { id } = useParams(); 
    const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
  const fetchPosts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add other headers like Authorization if needed
        },
        mode: 'cors', // Ensure that CORS is handled
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      
      const matchingPost = data.find(post => post._id === id);

      if (matchingPost) {
        setPost(matchingPost);
      } else {
        setError(new Error('Post not found'));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  fetchPosts();
}, [id]);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!post) {
    return <div>Post not found</div>;
  }

  const name = post.name || '';
  const [firstWord, ...restWords] = name.split(' ');
  const restOfName = restWords.join(' ');

  const head = post.heading2 || '';
  const [headWord, ...restHeads] = head.split(' ');
  const restOfHead = restHeads.join(' ');

  return (
    <>
    <div className='product_hero_div'>
            {post.files && post.files.length > 0 && (
              <div className="productpage_hero_img">
                <img src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[0]}`} alt="cap" className="blog_heroimg" />
                <div className="overlay_product_text">
                  <h2 className='product_hero_title'>{post.name}</h2>
                </div>
              </div>
            )}
        </div>
 <div className='container product_card_div'>
  <div className='row'>
    <div className='col-3 d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/water.webp" className="browndivimg" alt='icon'/></div>
     <div><p className='procard_text'>{post.box1}</p></div>
      </div>
    </div>
    <div className='col-3 d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/poke.webp" className="browndivimg"  alt='icon'/></div>
     <p className='procard_text'>{post.box2}</p>
      </div>
    </div>
    <div className='col-3 d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/harmonized.webp" className="browndivimg"  alt='icon'/></div>
     <p className='procard_text'>{post.box3}</p>
      </div>
    </div>
    <div className='col-3 d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/colloid.webp" className="browndivimg" alt='icon' /></div>
     <p className='procard_text'>{post.box4}</p>
      </div>
    </div>
  </div>

 </div>

 <div className='container mob_product_div'>
 <Swiper
     
      modules={[Navigation,  A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      loop={true}
     
    >

<SwiperSlide>
    <div className=' d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/water.webp" className="browndivimg"  alt='icon' /></div>
     <p className='procard_text'>{post.box1}</p>
      </div>
    </div>
    </SwiperSlide>
 
    <SwiperSlide>
    <div className=' d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/poke.webp" className="browndivimg"  alt='icon'/></div>
     <p className='procard_text'>{post.box2}</p>
      </div>
    </div>
    </SwiperSlide>
  
    <SwiperSlide>
    <div className=' d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/harmonized.webp" className="browndivimg"  alt='icon'/></div>
     <p className='procard_text'>{post.box3}</p>
      </div>
    </div>
    </SwiperSlide>
    
    <SwiperSlide>
    <div className='d-flex align-items-center justify-content-center'>
      <div className='product_brown_card'>
      <div> <img src="/colloid.webp" className="browndivimg" alt='icon'/></div>
     <p className='procard_text'>{post.box4}</p>
      </div>
    </div>
    </SwiperSlide>
 

  </Swiper>
 </div>

 <div className='container ps-5 pe-5'>
 <h4 className='productpage_heading'>
 <span className="first-word">{firstWord}</span>{' '}
 <span className="rest-words">{restOfName}</span>
        </h4>
  <div className='productpage_text mt-3' dangerouslySetInnerHTML={{ __html: post.content1 }} />
 </div>

 <div className='containers1'>
 <h4 className='productpage_heading'>
          <span className="head-word">{headWord}</span>{' '}
          <span className="rest-words">{restOfHead}</span>
        </h4>
       {post.lists && post.lists.length > 0 && (
          <div className='mt-4 app_list_div'>
         
              {post.lists.map((item, index) => (
                <li className='list_text' key={index}>{item}</li>
              ))}
          
          </div>
        )}
 </div>

<div className='work_maindiv d-md-flex'>
  <div className='col-md-6 col-sm-12'>
    <div className=' work_greendiv'>
    {post.files && post.files.length > 1 && (
<img className='work_img' src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[1]}`}  alt='sc'/>
    )}
    </div>

  </div>
  <div className='col-md-6 col-sm-12 '>
    <div className='work_bluediv'>
<h4 className='work_head'>How it <span className='color_work_head'> works?</span></h4>
<div className='work_content mt-4' dangerouslySetInnerHTML={{ __html: post.content2 }} />
    </div>
    
  </div>
  </div>  

  <div className='mt-5'>
<TotalSection4/>
  </div>
   
    </>
  )
}

export default ProductPage
