import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const HomeBlog = () => {
    const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
  const fetchPosts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/posts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers you might need here, like Authorization if required
        },
        mode: 'cors', // This ensures CORS is handled by the browser
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPosts(data.reverse());
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  fetchPosts();
}, []);

  const displayedPosts = posts.slice(0, 3);
  return (
    <div className="containers1 mt-2">
    <h2 className="homeabouthead">Our <span className="homeabouthead1"> Blogs</span></h2>
    <div className="blogcontainer mt-3">
      {displayedPosts.map((post, index) => (
        <div key={post._id} className='blogdiv'>
          {post.files && post.files.length > 0 && (
            <div className="blogcard_img">
              <img 
                src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[0]}`}
                alt={`blog`} 
                className="blogimg"
                loading="lazy" 
              />
            </div>
          )}
          <div className='overlay-blog'>
            <h2 className='blog-headtxt'>{post.name}</h2>
            <Link to={`/blogpage/${post._id}`} >
              <p className='blog_readbtn'>Read More &gt;&gt; </p>
            </Link>
          </div>
        </div>
      ))}
    </div>
    <div className="mob_blogcontainer mt-3 mb-5">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {displayedPosts.map((post, index) => (
          <SwiperSlide key={post._id}>
            <div className='blogdiv'>
              {post.files && post.files.length > 0 && (
                <div className="blogcard_img">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[0]}`}
                    alt={`blog`} 
                    className="blogimg"
                    loading="lazy"
                  />
                </div>
              )}
              <div className='overlay'>
                <h2 className='blog-headtxt'>{post.name}</h2>
                <Link href={`/blogpage/${post._id}`} >
                  <p className='blog_readbtn'>Read More &gt;&gt; </p>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
  )
}

export default HomeBlog
